<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loading">
          <template slot="header">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <span class="title">
                Agency Accounts 
              </span>
              <div class="d-flex justify-content-end align-items-center">
                <div>
                  <b-nav pills>
                    <b-nav-item active><router-link :to="{name: 'agencyaccounts'}">Active</router-link></b-nav-item>
                    <b-nav-item><router-link :to="{name: 'admin.agencies.archived'}">Archived</router-link></b-nav-item>
                  </b-nav>
                </div>
                <div class="searchForm pl-2">
                  <text-input name="search" v-model="search" placeholder="Search..." style="width: 250px"></text-input>
                  <button class="btn btn-default btn-primary btn-sm ml-2" @click="handleSearch()" :disabled="!search">
                    <i class="fa fa-search"></i> Filter
                  </button>
                  <button class="btn btn-default btn-primary btn-sm ml-2" @click="clearSearch()">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </template>
          <datatable v-bind="$data" class="le-datatable">
          </datatable>
        </vuestic-widget>
      </div>
    </div>
    <vuestic-modal
      :isOpen="isOpenModalDelete"
      @ok="handleArchiveAgency"
      @cancel="closeModalDelete"
      okText="Delete"
      cancelText="Cancel"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Delete Agency</span>
      <div v-if="selectedAgency">
        Confirm you want to delete the 
        <span v-if="selection.length">
          selected agencies
        </span>
        <span v-else>
          agency for {{ selectedAgency.name }}?
        </span>
      </div>
      <div>The agency will be archived for 15 days and then permanently deleted.</div>
    </vuestic-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import components from '../common/tables/comps'
import { mapGetters } from 'vuex'
export default {
  name: 'AgencyAccounts', // `name` is required as a recursive component
  props: ['row'], // from the parent FriendsTable (if exists)
  created() {
  },

  mounted()  {
    this.xprops.eventbus.$on('toggleActive', this.toggleActive)
    this.xprops.eventbus.$on('archiveAgency', (agency) => {
      this.isOpenModalDelete = true
      this.selectedAgency = agency
    })

    if (!this.$store.getters['subscription/isLoadedPlan']) {
      this.$store.dispatch('subscription/getPlans')
    }
  },

  data () {
    return {
      supportBackup: false,
      supportNested: true,
      HeaderSettings: false,
      tblClass: 'table-bordered',
      tblStyle: 'color: #666',
      pageSizeOptions: [10, 25, 50, 100],
      columns: (() => {
        const cols = [
          { title: 'ID', field: 'id', label: 'Agency ID', sortable: true, visible: true },
          { title: 'Agency Name', sortable: true, field: 'name', tdStyle: { fontStyle: 'normal' } },
          { title: 'Info', field: 'test', tdComp: 'TdAgencyMeta', sortable: false, visible:true, tdClass: 'left'},
          { title: 'Active', field: 'active', sortable: true, visible: true, tdClass: 'center', tdComp: 'AccountsActiveToggleTd'},
          { title: 'Template', field: 'is_template', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdBoolean', },
          { title: 'Verified', field: 'email_verified', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdBoolean', },
          { title: 'Operation', tdComp: 'AgencyOpt', visible: 'true', tdClass: 'center'},
          { title: '', tdComp: 'AgencyMore', visible: 'true', tdClass: 'center'},
        ];
        return cols;
      })(),
      data: [],
      total: 0,
      selection: [],
      summary: {},
      query: {
        status: 'active',
        search: null
      },
      search: null,
      xprops: {
        eventbus: new Vue(),
      },
      loading: false,
      selectedAgency: null,
      isOpenModalDelete: false,
    }
  },

  watch: {
    query: {
      handler () {
        this.handleQueryChange()
      },
      deep: true
    }
  },
  methods: {
    handleSearch () { 
      this.query.search = this.search
    },

    clearSearch () {
      this.search = null
      this.query.search = null
      this.handleQueryChange()
    },

    handleArchiveAgency () {
      if (this.selection.length) {
        this.bulkArchiveAgencies();
      } else {
        this.archiveAgency()
      }
    },

    archiveAgency () {
      this.loading = true
      this.$store.dispatch('agency/archiveAgency', this.selectedAgency.id)
        .then(res => {
          this.closeModalDelete()
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },

    bulkArchiveAgencies () {
      let agencyIds = this.selection.map(item => item.id)

      this.loading = true
      this.$store.dispatch('agency/bulkArchiveAgencies', agencyIds)
        .then(res => {
          this.closeModalDelete()
          this.selection = []
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },

    handleQueryChange () {
      this.loading = true
      this.$store.dispatch("agency/getAgencies", this.query).then(res => {
        this.loading = false
          this.data = res.data
          this.total = res.meta.total
      })
      .catch(error => {
        this.loading = false
      });
    },
    alertSelectedUids () {
      alert(this.selection.map(({ uid }) => uid))
    },

    closeModalDelete () {
      this.isOpenModalDelete = false
      this.selectedAgency = null
    },

    toggleActive (record) {
      const params = {
        active: record.active
      }
      axios.put(`/v1/agencies/${record.id}/toggle-active`, params)
          .then(() => {
            this.loading = false;
          }).catch((e) => {
            this.loading = false;
            console.log("Error", e);
        });
    }
  }
}
</script>
<style>
.w-240 {
  width: 240px;
}
</style>
